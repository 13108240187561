import Select from '@/views/components/config/Select.vue';
import Switch from '@/views/components/config/Switch.vue';
import Input from '@/views/components/config/Input.vue';
import DateTime from '@/views/components/config/DateTime.vue';
import BoxInput from '@/views/components/config/BoxInput.vue';
import Color from '@/views/components/config/Color.vue';
import UnitInput from '@/views/components/config/UnitInput.vue';

export default {
	select: Select,
	switch: Switch,
	input: Input,
	datetime: DateTime,
	boxInput: BoxInput,
	color: Color,
	unitInput: UnitInput
};
