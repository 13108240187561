import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import naive from 'naive-ui';

import {install} from '@icon-park/vue-next/es/all';

const vueApp: any = createApp(App).use(store).use(router).use(naive);
install(vueApp);
vueApp.mount('#app');
