import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {createDiscreteApi} from 'naive-ui';
import Index from '@/views/Index.vue';
import Promotion from '@/views/PromotionPage.vue';
import Design from '@/views/Design.vue';

const {loadingBar} = createDiscreteApi(['loadingBar']);
const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Index
	},
	{
		path: '/:id',
		name: 'promotion',
		component: Promotion
	},
	{
		path: '/promotion/:id',
		name: 'promotion2',
		component: Promotion
	},
	{
		path: '/design/:id',
		name: 'design',
		component: Design
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});
router.beforeEach((to, from, next) => {
	loadingBar.start();
	next();
});
router.afterEach(() => {
	loadingBar.finish();
});
export default router;
