import axios from 'axios';
import qs from 'qs';
import router from '@/router';
import GlobalConfig from '@/config';
import {createDiscreteApi} from 'naive-ui';
import {Response} from '@/model/Response';

const {notification} = createDiscreteApi(['notification']);
const DefaultParam = {repeatable: true};
const ajax = {
	PREFIX: GlobalConfig.host,
	Author: 'heyui',
	requestingApi: new Set(),
	extractUrl(url: string) {
		return url ? url.split('?')[0] : '';
	},
	isRequesting(url: string) {
		const api = this.extractUrl(url);
		return this.requestingApi.has(api);
	},
	addRequest(url: string) {
		const api = this.extractUrl(url);
		this.requestingApi.add(api);
	},
	deleteRequest(url: string) {
		const api = this.extractUrl(url);
		this.requestingApi.delete(api);
	},
	upload(url: string, formData: any) {
		const params = {
			url,
			method: 'POST',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: sessionStorage.getItem('auth') || ''
			}
		};
		return this.ajax(params, {});
	},
	get<T>(url: string, param?: any, extendParam?: any) {
		const params: { url: string, method: string, params: any } = {
			url,
			method: 'GET',
			params: null
		};
		if (param) {
			params.params = param;
		}
		return this.ajax<T>(params, extendParam);
	},
	post(url: string, param: any, extendParam?: any) {
		const params: { url: string, method: string, data: any } = {
			url,
			method: 'POST',
			data: null
		};
		if (param) params.data = qs.stringify(param);
		return this.ajax(params, extendParam);
	},
	postJson<T>(url: string, paramJson: any, extendParam?: any) {
		return this.ajax<T>({
			url,
			method: 'POST',
			data: paramJson
		}, extendParam);
	},
	patchJson<T>(url: string, paramJson: any, dataType: any, extendParam: any) {
		return this.ajax<T>({
			url,
			method: 'PATCH',
			data: paramJson
		}, extendParam);
	},
	delete(url: string, extendParam: any) {
		return this.ajax({
			url: url,
			method: 'DELETE'
		}, extendParam);
	},
	ajax<T>(param: any, extendParam: any): Promise<Response<T>> {
		let params = {...DefaultParam, ...param, ...extendParam};
		params.crossDomain = params.url.indexOf('http') === 0;
		let url = params.url;
		if (!params.crossDomain) {
			url = params.url = this.PREFIX + params.url;
		}
		if (params.method !== 'GET') {
			if (this.isRequesting(url)) {
				return new Promise((resolve, reject) => {
					resolve({ok: false, message: '重复请求'} as Response<any>);
				});
			}
			if (params.repeatable === false) {
				this.addRequest(url);
			}
		}
		const header = {
			author: this.Author,
			Authorization: (sessionStorage.getItem('auth') || '') === 'null' || (sessionStorage.getItem('auth') || '') === '' ? '' : sessionStorage.getItem('auth')
		};
		const defaultParam: { headers: any, responseType: string, validateStatus: (status: any) => {}, paramsSerializer: (params: any) => {} } = {
			headers: header,
			responseType: 'json',
			validateStatus: function (status: any) {
				return true;
			},
			paramsSerializer: (params: any) => {
				return qs.stringify(params, {allowDots: true});
			}
		};
		if (params.crossDomain) {
			defaultParam.headers = {};
		}
		const that = this;
		params = {...DefaultParam, ...defaultParam, ...params};
		return new Promise((resolve) => {
			return axios.request(params).then((response: any) => {
				that.deleteRequest(params.url);
				const data = response.data;
				const status = data.code;
				data.ok = status === 200;
				if (status === 401) {
					sessionStorage.setItem('auth', '');
					notification.warning({
						title: '授权失效',
						content: data.message,
						duration: 3000
					});
					router.push('/Login');
					return;
				}
				resolve(data);
			}).catch((response: any) => {
				console.log(response);
				notification.error({title: '出错', content: '连接服务器失败，请检查网络连接是否正常'});
				that.deleteRequest(params.url);
				resolve({
					ok: false
				} as Response<any>);
			});
		});
	}
};
export default ajax;
