import Name from './Name.vue';
import Phone from './Phone.vue';
import Image from '@/views/components/Image.vue';
import FormComponent from '@/views/components/Form.vue';
import Background from '@/views/components/Background.vue';
import Button from '@/views/components/Button.vue';
import LocalArea from '@/views/components/LocalArea.vue';
import Address from '@/views/components/Address.vue';
import AgreementComponent from '@/views/components/Agreement.vue';
import NavigationComponent from '@/views/components/Navigation.vue';
import CountDown from '@/views/components/CountDown.vue';
import VideoComponent from '@/views/components/Video.vue';
import TimeComponent from '@/views/components/Time.vue';
import Modal from '@/views/components/Modal.vue';
import TextComponent from '@/views/components/Text.vue';
import Space from '@/views/components/Space.vue';

import {
	ImageFiles,
	Form,
	User,
	Butterfly,
	MapRoad,
	PhoneTelephone,
	AddressBook,
	Agreement,
	Navigation,
	ClockTower,
	Video,
	Time,
	AddMode,
	Text,
	SpaceColony
} from '@icon-park/vue-next';

const commonProps = [
	{
		name: 'position',
		title: '展示方式',
		type: 'select',
		options: [
			{label: '自动', value: ''},
			{label: '静态', value: 'static'},
			{label: '相对', value: 'relative'},
			{label: '绝对', value: 'absolute'},
			{label: '固定', value: 'fixed'}
		]
	},
	{
		name: 'top',
		title: '上边位置',
		type: 'unitInput'
	},
	{
		name: 'right',
		title: '右边位置',
		type: 'unitInput'
	},
	{
		name: 'bottom',
		title: '下边位置',
		type: 'unitInput'
	},
	{
		name: 'left',
		title: '左边位置',
		type: 'unitInput'
	},
	{
		name: 'width',
		title: '宽度',
		type: 'unitInput'
	},
	{
		name: 'height',
		title: '高度',
		type: 'unitInput'
	},
	{
		name: 'padding',
		title: '内边距',
		type: 'boxInput'
	},
	{
		name: 'margin',
		title: '外边距',
		type: 'boxInput'
	},
	{
		name: 'fontSize',
		title: '字体大小',
		type: 'unitInput'
	},
	{
		name: 'color',
		title: '字体颜色',
		type: 'color'
	},
	{
		name: 'backgroundColor',
		title: '背景颜色',
		type: 'color'
	},
	{
		name: 'backgroundImage',
		title: '背景图片',
		type: 'input',
		media: true,
		url: true
	},
	{
		name: 'backgroundSize',
		title: '背景大小',
		type: 'select',
		options: [
			{label: '封面', value: 'cover'},
			{label: '填充', value: 'contain'}
		]
	}
];

export default {
	name: {
		title: '姓名',
		desc: '可用于输入客户姓名',
		icon: User,
		component: Name,
		props: [
			...commonProps
		]
	},
	phone: {
		title: '电话',
		desc: '可用于输入客户手机号',
		icon: PhoneTelephone,
		component: Phone,
		props: [
			{
				title: '使用发送短信号码作为默认手机号',
				name: 'useSms',
				type: 'switch'
			},
			...commonProps
		]
	},
	image: {
		title: '图片',
		desc: '支持向页面中插入图片',
		icon: ImageFiles,
		component: Image,
		props: [
			{
				name: 'src',
				title: '地址',
				type: 'input',
				media: true,
				multiple: false
			},
			...commonProps
		]
	},
	form: {
		title: '表单',
		desc: '用于收集客户信息的表单',
		icon: Form,
		component: FormComponent,
		container: true,
		props: [
			...commonProps
		]
	},
	background: {
		title: '背景图',
		desc: '用于改变页面背景图',
		icon: ImageFiles,
		component: Background,
		props: [
			{
				name: 'src',
				title: '地址',
				type: 'input',
				media: true,
				multiple: false
			},
			...commonProps
		]
	},
	button: {
		title: '提交按钮',
		desc: '用于表单最后的提交操作',
		icon: Butterfly,
		component: Button,
		props: [
			{
				name: 'color',
				title: '颜色',
				type: 'select',
				options: [
					{label: '红色', value: 'error'},
					{label: '橙色', value: 'warning'},
					{label: '绿色', value: 'primary'},
					{label: '蓝色', value: 'info'},
					{label: '白色', value: 'default'}
				]
			},
			{
				name: 'block',
				title: '单独块',
				type: 'switch'
			},
			{
				name: 'text',
				title: '按钮名称',
				type: 'input'
			},
			{
				name: 'showModal',
				title: '显示提交等待框',
				type: 'switch'
			},
			...commonProps
		]
	},
	localArea: {
		title: '安装区域（成都市）',
		desc: '快捷选择成都市下面的所以区县以及县级市',
		icon: MapRoad,
		component: LocalArea,
		props: [
			...commonProps
		]
	},
	address: {
		title: '安装地址',
		desc: '填写详细的安装地址，例如小区',
		icon: AddressBook,
		component: Address,
		props: [
			...commonProps
		]
	},
	agreement: {
		title: '免责协议',
		desc: '展示免责声明，必要时可以设置用户勾选之后才提交表单',
		icon: Agreement,
		component: AgreementComponent,
		props: [
			{
				name: 'required',
				title: '必须同意',
				type: 'switch'
			},
			{
				name: 'agreementName',
				title: '协议名称',
				type: 'input'
			},
			{
				name: 'agreementContent',
				title: '协议内容',
				type: 'input',
				textArea: true,
				pre: true
			},
			...commonProps
		]
	},
	navigation: {
		title: '快速导航',
		desc: '当用户滑动到表单以外时，可以通过点击快速回到表单提交处',
		icon: Navigation,
		component: NavigationComponent,
		props: [
			{
				title: '可见元素',
				name: 'element',
				type: 'select',
				trigger: true
			},
			...commonProps
		]
	},
	countDown: {
		title: '倒计时',
		desc: '展示一个有趣的倒计时',
		icon: ClockTower,
		component: CountDown,
		props: [
			{
				name: 'date',
				title: '到期时间',
				type: 'datetime'
			},
			...commonProps
		]
	},
	video: {
		title: '视频',
		desc: '插入一个可以播放的视频',
		icon: Video,
		component: VideoComponent,
		props: [
			{
				name: 'src',
				title: '视频地址',
				type: 'input',
				media: true
			},
			{
				name: 'cover',
				title: '封面地址',
				type: 'input'
			},
			{
				name: 'auto',
				title: '自动播放',
				type: 'switch'
			},
			...commonProps
		]
	},
	time: {
		title: '上门时间',
		desc: '输入一个用户可以选择的上门时间',
		icon: Time,
		component: TimeComponent,
		props: [
			...commonProps
		]
	},
	modal: {
		title: '二次弹窗',
		desc: '提供一个弹窗，也可以在弹窗中放入组件',
		icon: AddMode,
		component: Modal,
		container: true,
		props: [
			{
				name: 'title',
				title: '弹窗标题',
				type: 'input'
			},
			{
				name: 'trigger',
				title: '触发器',
				type: 'select',
				trigger: true
			},
			...commonProps
		]
	},
	text: {
		title: '文字',
		desc: '放置一段文本，可以是任何文字内容包括emoji',
		icon: Text,
		component: TextComponent,
		props: [
			{
				name: 'content',
				title: '内容',
				type: 'input',
				textArea: true
			},
			...commonProps
		]
	},
	space: {
		title: '间距',
		desc: '使元素之间有间距',
		icon: SpaceColony,
		component: Space,
		props: [
			{
				name: 'align',
				title: '水平排列方式',
				type: 'select',
				options: [
					{label: '开始', value: 'start'},
					{label: '结束', value: 'end'}
				]
			},
			{
				name: 'vertical',
				title: '垂直排列',
				type: 'switch'
			},
			...commonProps
		]
	}
};
