<template>
	<n-h3 style="text-align: center">请点击下方的保存按钮之后再进行预览</n-h3>
	<n-button @click="toPromotion">go</n-button>
</template>

<script setup>
import {useRouter} from 'vue-router';

const router = useRouter();
const toPromotion = () => {
	router.push('promotion');
};
</script>

<style scoped>

</style>
